import { createTheme, Theme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
import style from 'common.module.scss';

const theme: Theme = createTheme(
    {
        palette: {
            primary: {
                main: style.primary,
            },
        },
        components: {
            MuiTextField: {
                defaultProps: {
                    variant: 'filled',
                },
            },
            MuiButton: {
                defaultProps: {
                    variant: 'contained',
                    size: 'large',
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    label: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        textAlign: 'center',
                    },
                },
            },
        },
    },
    frFR,
);

export default theme;
