import { ThemeProvider } from '@mui/material/styles';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import muiTheme from 'muiTheme';
import 'globals.css';
import { CookiesProvider } from 'react-cookie';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const Login = lazy(() => import('./pages/Login'));

const App = () => (
    <ThemeProvider theme={muiTheme}>
        <CookiesProvider>
            <Suspense fallback="">
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Dashboard />} />
                </Routes>
            </Suspense>
        </CookiesProvider>
    </ThemeProvider>
);

export default App;
